import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_ASSESSMENT_API_URL
});
const questionsInstance = axios.create({
    baseURL: process.env.VUE_APP_QUESTIONS_API_URL
});

export default {
    createStaticAssessment: async function (formData) {
        try {
            //console.log("pushing data",formData)
            const response = await instance.post('static-assessment', formData, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getStaticAssessments: async function (status, searchValue="") {

        try {
            const response = await instance.get(`static-assessments?status=${status}&searchQuery=${searchValue}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    getStaticAssessmentCounts: async function () {
        try {
            const response = await instance.get(`static-assessments-counts`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    deleteStaticAssessmentById: async function (documentId) {
        try {
            const response = await instance.delete(`static-assessments?id=${documentId}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch(error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    getStaticAssessmentsById: async function (docId, pageSize, page) {

        try {
            const response = await instance.get(`staticAssessmentById?id=${docId}&page=${page}&pageSize=${pageSize}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    updateStaticAssessment: async function (reqPayload) {
        try {
            const response = await instance.put('staticAssessmentById', reqPayload, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    filterStaticAssessmentList: async function ({assessmentStatus=[], languages = [], grades = [], subjects = [], skills = [] ,levels = []}) {
        try {
            const response = await instance.get(`filterStaticAssessment?assessmentStatus=${window.btoa(assessmentStatus)}&languages=${window.btoa(languages)}&grades=${window.btoa(grades)}&subjects=${window.btoa(subjects)}&skills=${window.btoa(skills)}&levels=${window.btoa(levels)}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch(error) {
            return error.response;
        }
    },
    async getAccessToken(assessmentId) {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_AUTH_API_URL}mocktestjwtsign`,
            data:{assessmentId:assessmentId}
        };
        return await axios.request(config);
    },

    editAssessmentQuestion: async function (reqPayload) {
        try {
            const response = await instance.put('editQuestionById', reqPayload, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
        } catch(error) {
            return error.response;
        }
    }


}
<template>
  <div>
    <!-- <v-dialog v-model="approvalDialog" max-width="647px" class="cdz" persistent>
      <v-card fluid>
        <v-container fluid class="p-6">
          <v-card-text class="text-center">
            <p class="text-h5 pt-3 pb-4">Send for Approval
            </p>
            <div class="d-flex">
              <v-autocomplete
              v-model="approverData.roleId"
              label="Role"
              :items="roles"
              item-text="roleName"
              item-value="id" 
              @change="getUsersForRole"
              outlined
              class="rounded-xl ma-2"
              required
              attach>
              </v-autocomplete>
              <v-autocomplete
              v-model="approverData.userId"
              label="User Name"
              :items="usersForRole"
              item-text="name"
              item-value="uid"
              :disabled="usersForRole.length==0"
              outlined
              class="rounded-xl ma-2"
              required
              attach>
              </v-autocomplete>
            </div>
            
            <div class="d-flex justify-end pb-2" fluid>
              <v-btn
                depressed
                class="btn-color--text mr-4"
                large
                width="107px"
                outlined
                rounded
                @click="closeApprovalDialog"
                >CANCEL</v-btn>
              <v-btn
                class="accent white--text"
                depressed
                large
                width="107px"
                rounded
                :disabled="approverData.userId == ''"
                :loading="sendForApprovalLoader"
                @click="sendForApproval"
                >SEND
              </v-btn>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="assessmentPublishedDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">
              Assessment Published Successfully
            </p>
            <v-btn
              class="accent"
              large
              width="157px"
              rounded
              @click="$router.push('/static-assessment')"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assessmentDraftDialog" max-width="366px" persistent>
      <v-card>
        <v-container fluid class="pa-8">
          <v-card-text class="text-center">
            <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
            <p class="text-h5 py-4">
              Assessment Draft Saved
            </p>
            <v-btn
              class="accent"
              large
              width="157px"
              rounded
              @click="$router.push('/static-assessment')"
              >OK</v-btn
            >
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-card height="64px" width="100%"
    class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
    style="
      position: fixed;
      z-index: 200;
      top: 0;
      width: 98%;
      padding-top: 8px;
    ">
      <v-card-title class="ml-0">
        <span @click="$router.push('/static-assessment')" class="breadcrumb-text cursor">
          Assessment
        </span>
        <v-icon class="breadcrumb-arrow">mdi-chevron-right</v-icon>
        <p class="breadcrumb-text">{{`${assessmentData.isEdit ? 'Edit' : 'Create'} Assessment`}}</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>
        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.Id }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div class="background" hide-overlay>
      <v-card class="background mt-4">
        <v-card-title class=" fixBarCreateCammpaign pt-2">
          <v-toolbar elevation="0" class="background px-3">
            <v-toolbar-title class="text-h5">{{`${assessmentData.isEdit ? 'Edit' : 'Create'} Assessment`}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row align="center" class="justify-end">
              <v-btn
                v-if="assessmentData.status == 'DRAFT'"
                depressed
                class="btn-color--text mx-2"
                large
                outlined
                rounded
                :disabled="this.assessmentData.assessmentName == ''"
                @click="createStaticAssessment(assessmentData, 'DRAFT')"
                >SAVE AS DRAFT</v-btn>
              <v-btn
                  depressed
                  class="btn-color--text mx-2"
                  large
                  outlined
                  rounded
                  @click="cancelStaticAssessmentCreation"
                  >CANCEL</v-btn>
              <v-btn v-if="e1 > 1 && e1 <= 2
                " outlined rounded class="btn-color--text" @click="goBack()">back</v-btn>
                <v-btn  v-if="!isLastStep"  :disabled="assessmentFormNotFilled" rounded depressed color="btn-color" class="ml-2 white--text"
                  @click="goto(e1)">
                  next
                </v-btn>
                
                <v-btn v-if="isLastStep"  rounded depressed color="btn-color" :disabled="assessmentData.noOfQuestion != allQuestions.length" class="ml-2 white--text"
                  @click="goto(e1)" :loading="successDialogLoader">
                  {{`${assessmentData.isEdit ? 'Update' : 'Publish'}`}}
                </v-btn>
            </v-row>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="e1" elevation="0" class="background pad-create-assessment">
            <v-stepper-header class="mx-6 white rounded-xl stepper-shadow">
              <v-stepper-step class="green--text py-0" complete-icon="mdi-check-bold" color="#4D695B" :complete="e1 > 1" step="1">
                ASSESSMENT CONFIGURATION
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step class="green--text py-0" complete-icon="mdi-check-bold" color="#4D695B" :complete="e1 > 2" step="2">
                ADD QUESTION
              </v-stepper-step>
            </v-stepper-header>
            <!-- stepper items starts here -->
            <v-card elevation="0" class="background mt-0 stepper-content-height rounded-xl mt-3" id="myScroll">
              <v-stepper-items>
                <v-stepper-content class="pt-0" step="1">
                  <v-form ref="step1" lazy-validation>
                    <v-card outlined elevation="0" class="rounded-xl">
                      <v-card-text class="pt-10 px-10">
                        <v-row class="py-0">
                          <v-col class="py-0">
                            <v-autocomplete
                              v-model="assessmentData.modeofAssessment"
                              label="Select Mode of Static Assessment*"
                              :items="ModeofAssessmentList"
                              outlined
                              class="rounded-xl"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  'Please Select Mode of Static Assessment type',
                              ]"
                              required
                              attach
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col class="py-0">
                            <v-text-field
                              outlined
                              class="rounded-xl"
                              v-model="assessmentData.assessmentName"
                              placeholder="Assessment Name Here"
                              label="Assessment Name*"
                              required
                              :rules="[(v) => !!v || 'Assessment Name is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="py-1 ma-0">
                          <v-col class="py-0 px-0">
                            <div class="text-body-1 pt-1">
                              Assessment Description
                            </div>
                            <ckeditor
                              :config="editorConfig"
                              v-model="assessmentData.assessmentDescription"
                              class="m-ckeditor"
                              :rules="[
                                (v) => !!v || 'Assessment Description is required',
                              ]"
                              required
                              @ready="onEditorReady"
                            >
                            </ckeditor>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" class="py-0">
                            <v-autocomplete
                              @input="handleInputChange"
                              v-model="assessmentData.availableLanguages"
                              clearable
                              deletable-chips
                              label="Select Assessment Language*"
                              outlined
                              class="rounded-xl"
                              chips
                              :items="languageOptions"
                              multiple
                              flat
                              solo
                              required
                              :rules="[(v) => !!v || 'languages is required']"
                              @click:clear="handleClear"
                            >
                              <template #selection="{ item }">
                                <v-chip
                                  @click:close="deleteChip('languages', item)"
                                  close-icon="mdi-close"
                                  text-color="white"
                                  close
                                  color="#4D695B"
                                  >{{ item }}</v-chip
                                >
                              </template>
                              <v-list-item
                                slot="prepend-item"
                                ripple
                                @click="selectAll('languages')"
                              >
                                <v-list-item-action>
                                  <v-checkbox :value="assessmentData.availableLanguages.length === languageOptions.length"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-slot:item="{ item, on, attrs }">
                                <v-list-item
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="defaultLanguages.includes(item)"
                                >
                                  <v-list-item-action>
                                    <v-checkbox :input-value="assessmentData.availableLanguages.includes(item)"></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="2" class="py-0">
                            <v-label> Reshuffle Required?* </v-label>
                            <v-radio-group class="radio-group-align" v-model="assessmentData.isReshuffleRequired" required>
                              <div class="d-flex ma-0">
                                <v-radio class="pr-4" label="YES" value="YES"></v-radio>
                                <v-radio label="NO" value="NO"></v-radio>
                              </div>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" class="py-0">
                            <v-autocomplete clearable label="Choose Levels*" outlined v-model="assessmentData.level"
                              required :rules="[(v) => !!v || 'Levels is required']" class="rounded-xl" :items="levels"
                              item-text="name" item-value="name" flat solo
                              @change="getGradesMappedToLevels($event, false)"
                              >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-autocomplete
                            @input="handleInputChange"
                            v-model="assessmentData.grade"
                            label="Select Grade*"
                            :items="grades"
                            outlined
                            :disabled="assessmentData?.level=='' || assessmentData?.level== undefined "
                            :menu-props="{ top: true, offsetY: true }"
                              class="rounded-xl"
                              :rules="[(v) => !!v || 'Please Select Grade']"
                              required
                              attach
                              >
                            </v-autocomplete>
                          </v-col>
                          
                        </v-row>
                        <v-row>
                          <v-col cols="3" class="py-0">
                            <v-text-field type="number" outlined class="rounded-xl"  :min="0" label="No. of Questions per Language*" v-model="assessmentData.noOfQuestion" required
                            @input="handleInputChange">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-stepper-content>
                
                <v-stepper-content class="pt-0" step="2">
                <v-form ref="step2">
                    <div class="d-flex" style="font-size: 14px;">
                      <v-card outlined class="d-flex flex-column pa-2 ma-1 assessment-details rounded-xl w-25">
                        <div class="d-flex pa-2 pb-2">
                          <div class="pr-2 label-text">
                            Language
                          </div>
                          <div>
                            <span class='ml-2 i-patch' v-for="(item, index) in assessmentData?.availableLanguages" :key="index">
                              {{ item }}<span  v-if="index <  assessmentData?.availableLanguages?.length - 1">, </span>
                            </span>
                          </div>
                        </div>
                        <div class="d-flex pa-2 pb-2">
                          <div class="pr-2 ">
                            Grade
                          </div>
                          <div class="i-patch">
                            {{ assessmentData?.grade  }}
                          </div>
                        </div>
                      </v-card>
                      <v-card outlined class="d-flex justify-space-around w-50 pa-2 ma-1 assessment-details rounded-xl">
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1 i-patch">
                            English
                          </div>
                          <div class="d-flex py-1">
                            <div class="pr-2">Question</div>
                            <div class="i-patch">{{englishCount}}</div>
                          </div>
                        </div>
                        <v-divider vertical class="create-assessment-divider"></v-divider>
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1 i-patch">
                            Mathematics
                          </div>
                          <div class="d-flex py-1">
                            <div class="pr-2">Question</div>
                            <div class="i-patch">{{mathCount}}</div>
                          </div>
                        </div>
                        <v-divider vertical class="create-assessment-divider"></v-divider>
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1"  style="font-weight: 600;">
                            Logical Reasoning
                          </div>
                          <div class="d-flex  py-1">
                            <div class="pr-2">Question</div>
                            <div class="i-patch">{{logicalReasoningCount}}</div>
                          </div>
                        </div>
                        <v-divider vertical class="create-assessment-divider"></v-divider>
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1 i-patch">
                            Science
                          </div>
                          <div class="d-flex py-1">
                            <div class="pr-2">Question</div>
                            <div class="i-patch">{{scienceCount}}</div>
                          </div>
                        </div>                        
                      </v-card>
                      <v-card outlined class="d-flex justify-space-around w-25 pa-2 ma-1 assessment-details rounded-xl total-questions-and-marks-card">
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1 i-patch">Total Questions</div>
                          <div class="py-1 label-value">{{ allQuestions.length }}</div>
                        </div>
                        <v-divider vertical class="total-questions-and-marks-divider"></v-divider>
                        <div class="d-flex flex-column pa-2">
                          <div class="py-1 i-patch">Total Marks</div>
                          <div class="py-1 label-value">{{ totalMarks }}</div>
                        </div> 
                      </v-card>
                    </div>
                
                  
                  <v-card outlined class="mt-3 rounded-xl" v-if="allQuestions.length <= 0">
                    <v-card-actions class="px-3 pb-6">
                      <v-btn @click="downloadTemplate" text class="mx-2 downloadColor">
                        <img class="mr-2" src="../../public/svgs/fi_share.svg" />
                        <span class="downloadColor label-value"> Download Template Excel </span>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <div class="d-flex justify-center mb-6 bg-surface-variant">
                      <div class="box2" @dragover.prevent>
                        <div class="w-100 d-flex flex-row justify-center" style="margin-top: 80px">
                          <img src="../../public/svgs/xlsx_icon 1.svg" />
                        </div>
        
                        <div class="w-100 d-flex flex-row justify-center mt-4">
                          <div>
                            <div class="font-weight-light text-center">
                              Drag and drop a file or
                              <div class="uplaodbtn2">
                                browse file
                                <input type="file" accept=".csv " @change="handleFileUpload" name="csv" />
                              </div>
                              <p class="grey--text">(Supported only .csv file)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <v-card outlined class="mt-3 pa-4 rounded-xl" v-else>
                    <v-row>
                      <v-col cols="4">
                        <span style="font-size: 22px;">Questions</span>
                      </v-col>
                      <v-col cols="8">
                        <div class="d-flex justify-end pb-4">
                          <v-btn  outlined rounded class="btn-color--text" :disabled="selectedQuestions.length == 0" @click="deleteDialog = true">
                          Delete</v-btn>
                          <template>
                          <div>
                            <v-btn outlined rounded depressed class="btn-color--text ml-2" @click="selectFile" :disabled=" assessmentData.noOfQuestion == this.allQuestions.length">
                              <v-icon>mdi-plus</v-icon>Add More
                            </v-btn>
                            <input
                              type="file"
                              ref="fileInput"
                              @change="handleFileUpload"
                              style="display: none;"
                            />
                          </div>
                        </template>
                          <!-- <v-btn  outlined rounded class="btn-color--text ml-2"  dark >
                            <v-icon>mdi-plus</v-icon>Add More</v-btn> -->
                        </div> 
                      </v-col>
                    </v-row>
                    
                    <v-card>
                      <template>
                        <v-data-table
                          v-model="selectedQuestions"
                          :single-select="singleSelect"
                          :headers="headers"
                          :items="allQuestions"
                          :items-per-page="5"
                          item-key="questionId"
                          class="elevation-1"
                          show-select
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                          <div>
                            <img width="36px" height="36px"  class="cursor" src="../assets/edit.svg" @click="redirectToEditStaticAssessmentQuestion(item.questionId)"/>
                          </div>
                          </template>
                        </v-data-table>
                      </template>
                    </v-card>
                    
                  </v-card>
    
                  <!-- <v-dialog max-width="600px" max-height="80vh" v-model="uploadingTopicsDialog" center> -->
                    <v-dialog  class="cdz" max-width="600px" v-model="uploadingTopicsDialog">
                      <v-card  width="100%" class="ma-0 pa-0 rounded-t-lg " id="myScroll">
                        <v-card elevation="0" height="40px"
                          class="w-100 outlined white--text d-flex pl-8 pr-2 align-center justify-space-between primary rounded-t-lg card-header-layout">
                          <div class="text--primary text-h6 font-weight-medium">
                            Upload Questions
                          </div>
                          <v-btn @click="onCancel" icon large>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card>
                      </v-card>
                      <v-card class="pa-4">
                        <v-row>
                          <v-col cols="4">
                            <div class="w-100 d-flex flex-row justify-center">
                             <img src="../../public/svgs/xlsx_icon 1.svg" />
                          </div>
                          </v-col>
                          <v-col cols="8">
                            {{fileName}}
                          </v-col>
                        
                          <v-col cols="12">
                         
                            <v-row class="text-center">
                              <v-col cols="6" md="6">
                                <p style="font-size: 14px; color:green">Successful: {{ successfullRowCount }}</p>
                               
                              </v-col>
                              <v-col cols="6" md="6">
                                <p style="font-size: 14px; color:red">Unsuccessful: {{ failedRowCount  }}</p>
                               
                              </v-col>
                            </v-row>
                          <v-row class="mx-4">
                            <v-col cols="6" md="6" class="w-100">
                              <v-btn  outlined rounded class="mx-3 btn-color--text" block @click="downloadFailedRows" :disabled="failedRowCount <= 0">
                              Download Error LOG</v-btn>
                            </v-col>
                            <v-col cols="6" md="6" class="w-100">
                              <v-btn  depressed rounded class="mx-3 accent" :disabled="successfullRowCount <= 0 ||  singleQuestionFailed == true" @click="uploadConfirm" block>
                              Upload</v-btn>
                            </v-col>
                          </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                  </v-dialog>


                  

                  <v-dialog v-model="showConfirmDialog" class="cdz" max-width="400px" min-height="294px" persistent>
                  <v-card>
                    <v-container fluid class="pa-2">
                      
                      <v-card-text class="text-center" >
                        <img src="../assets/done.svg">
                        <p class="text-h5 py-4">Questions Added Successfully</p>
                        
                        <v-btn class="accent w-100" large  @click="displayQuestionList" rounded>OK</v-btn>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-dialog>


                <v-dialog v-model="alertDialog" class="cdz" max-width="424px" min-height="303px" persistent>
                <v-card>
                  <v-container fluid class="pa-8">
                    <v-card-text class="text-center">
                      <img src="../assets/warning.svg">
                      <p class="text-h5 py-4">Questions count mismatch</p>
                      <v-btn class="accent" large width="207px" @click="closeAlert" rounded >OK</v-btn>
                    </v-card-text>
                  </v-container>
                </v-card>
              </v-dialog>

              <v-dialog v-model="deleteDialog" class="cdz" max-width="400px" min-height="294px" persistent>
                  <v-card>
                    <v-container fluid class="pa-2">
                      
                      <v-card-text class="text-center" >
                        <img src="../assets/done.svg">
                        <p class="text-h5 py-4">Question deleted Successfully</p>
                        
                        <v-btn class="accent w-100" large  @click="removeSelectedQuestions" rounded>OK</v-btn>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-dialog>

                </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-card>
            
          </v-stepper>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import "../styles.css";
import AssessmentController from "@/controllers/AssessmentController";
import LevelController from "@/controllers/LevelController";
import GradeController from "@/controllers/GradeController";
import axios from "axios";
import Papa from "papaparse";
import TopicsController from "@/controllers/DemoTopicsController";
import AuthService from "../services/AuthService";
import RolesController from "@/controllers/RolesController";
import * as XLSX from 'xlsx';
//import XLSXStyle from 'xlsx-style';

import StaticAssessmentController from "@/controllers/StaticAssessmentController";
import { mapGetters } from "vuex";
export default {
  name: "CreateStaticAssessment",

  data() {
    // ...mapGetters
    return {
      roles:[],
      alertDialog:false,
      usersForRole:[],
      bulkTopicUploadResponse: [],
      fileName:"",
      approverData:{
        roleId:"",
        userId:"",
      },
      levels:[],
      openApprovalDialogLoader: false,
      successDialogLoader: false,
      approvalDialog: false,
      sendForApprovalLoader: false,
      assessmentPublishedDialog: false,
      assessmentDraftDialog: false,
      defaultLanguages: ["English"],
      languageOptions: ["English", "Hindi", "Marathi"],
      assessmentData: {
        id: "",
        noOfQuestion:0,
        availableLanguages: [],
        assessmentDescription: "",
        grade: null,
        level: null,
        assessmentName: "",
        modeofAssessment: "Static Upload",
        isReshuffleRequired: "YES",
        questions:[],
        status: "",
        deletedQuestions: [],
        isEdit:false
        // assessmentDetails: {}
      },
      dialog: true,
      e1: 1,
      isDownloadtemplete:true,
      uploadingTopicsDialog: false,
      showConfirmDialog:false,
      displayQuestion:false,
      ModeofAssessmentList: ["Static Upload", "Dynamic Upload"],
      editorConfig: {
        height:60,
        extraPlugins: "ckeditor_wiris",
      },
      grades: [],
      newgradeList: [],
      pageSize: 10,
      page: 1,
      dataTableOptions: {},
      selectedTableItems: [],
      questionsList:[],
      newquestionsList:[],
      newQuestionCount: 0,
      singleSelect: false,
      questionTypeList: [
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "FILL_IN_THE_BLANKS",
        "TRUE_FALSE",
        "MATCH_THE_FOLLOWING",
      ],
      bloomsTaxonomyLevel: [
        "REMEMBER",
        "UNDERSTAND",
        "APPLY",
        "ANALYZE",
        "EVALUATE",
        "CREATE",
      ],
      typeOfAudienceOptions: [{ name: "Student" }, { name: "Teacher" }, { name: "Both" }],
      authoredBy: null,
      authoredByOptions: [{ name: "HubbleHox", value: "HH" }, { name: "Vendor Acquired", value : "VA" }, 
      { name: "Acquired from Publications", value: "AP" }, { name: "Acquired From Book", value: "AB" }, { name: "Practice guide", value : "PG" }, 
      { name: "Ampersand", value: "AMPERSAND" }],
      knowledgeLevels: ["MUST_KNOW", "SHOULD_KNOW", "NICE_TO_KNOW"],
      difficultyLevels: ["EASY", "MEDIUM", "HARD", "VERY_HARD"],
      complexityLevels: ["P1", "P2", "P3", "P4", "P5"],
      checkData: {
        skills: [],
        levels: [],
        grades: [],
        subjects: [],
      },
      removedQuestion: [],
      bulkQuestionJsonData:[],
      allQuestions:[],
      uniqueSkills:[],
      failedRows:[],
      failedRowCount:0,
      successfullRowCount:0,
      questionsToUpload :[],
      headers: [
          {
            text: 'Question Id',
            align: 'start',
            sortable: false,
            value: 'questionId',
          },
          { text: 'Question Statement', sortable: false, value: 'questionStatement' },
          { text: 'Skills', sortable: false, value: 'skill' },
          { text: 'Score', sortable: false, value: 'marksAllotted' },
          { text: 'Difficulty Level', sortable: false, value: 'difficultyLevel' },
          { text: 'Grade', sortable: false, value: 'grade' },
          { text: 'Actions', sortable: false, value: 'actions' },
        ],
      singleSelect: false,
      selectedQuestions: [],
      // addedQuestions: [],
      questionIds:[],
      englishCount: 0,
      mathCount: 0,
      logicalReasoningCount: 0,
      scienceCount: 0,
      totalMarks: 0,
      isAddMoreEnabled:false,
      deleteDialog:false,
      singleQuestionFailed:false
    };
  },

  watch: {
            dataTableOptions: {
            handler() {
                console.log("dataTableOptions entered", this.dataTableOptions);
                this.pageSize = this.dataTableOptions.itemsPerPage;
                this.page = this.dataTableOptions.page;
                let len=Number(Number(this.page ?? 1)*Number(this.pagesize ?? 10))-9 ;
                this.newquestionsList=[];
                let size=Number(Number(this.page ?? 1)*Number(this.pagesize ?? 10));
                console.log("len",len,"size",size,"this.questionsList.length",this.questionsList.length)
                for(let i=len;i < size;i++){
                  if(len <= this.questionsList.length){
                    this.newquestionsList.push(this.questionsList[i])
                  }
                }
            },
            deep: true
        },
      },

  methods: {
    handleInputChange() {
      // Reset allQuestions whenever the input changes
      this.allQuestions = [];
      this.totalMarks = 0;
      this.scienceCount = 0;
      this.logicalReasoningCount = 0;
      this.englishCount = 0;
      this.mathCount = 0;
    },
    openCountErrorDialog(){
      this.alertDialog = true;
    },
    closeAlert(){
      this.alertDialog = false;
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    removeSelectedQuestions(){
      this.assessmentData.deletedQuestions = this.selectedQuestions.map(question=>question.questionId);
      this.allQuestions = this.removeQuestionIdsFrom(this.allQuestions, this.assessmentData.deletedQuestions);
      this.questionsToUpload = this.removeQuestionIdsFrom(this.questionsToUpload, this.assessmentData.deletedQuestions);
      this.bulkQuestionJsonData = this.removeQuestionIdsFrom(this.bulkQuestionJsonData, this.assessmentData.deletedQuestions);
      this.assessmentData.questions = this.removeQuestionIdsFrom(this.assessmentData.questions, this.assessmentData.deletedQuestions);
      this.getSubjectCount();
      this.calculateTotalMarks();
      this.selectedQuestions = [];
      this.deleteDialog = false;
    },
    removeQuestionIdsFrom(questionsList, questionIdsTobeRemoved){
      return questionsList.filter(question => !questionIdsTobeRemoved.includes(question.questionId))
    },
    getGradesMappedToLevels(event, invokedForDataPopulation=false){
      if(!invokedForDataPopulation){
        this.grades = [];
        this.assessmentData.grade = '';
      }
      this.levels.forEach(level=>{
        if(this.assessmentData.level == level.name){
          this.grades.push(...level.grades)
        }
      })
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        if (response.data.levels.length > 0) {
          this.levels = response.data.levels;
        } else {
          this.levels = [];
        }
      }
    },
    getUniqueSubjects() {
      const uniqueSubjects = new Set(); // To store unique subjects

      this.allQuestions.forEach(item => {
        if (item.subject.length > 0) {
            uniqueSubjects.add(item.subject[0]); // Add the first element of the subject array to the set
        }
      });

      return Array.from(uniqueSubjects); // Convert Set to Array and return
    },
    getUniqueSkills() {
      const uniqueSkills = new Set(); // To store unique subjects

      this.allQuestions.forEach(item => {
        uniqueSkills.add(item.skill); // Add subject to the set
      });

      return Array.from(uniqueSkills); // Convert Set to Array and return
    },
    async createStaticAssessment(assessment, status){
      this.successDialogLoader = true;
      
       //dummyQuestions to be removed once dev complete on questions csv validation
       let dummyQuestions = [
          {
              "questionId": 1,
              "difficultyLevel": "EASY",
              "subject": [
                  "Social Science",
                  "Geography"
              ],
              "questionAssetUrl": "NA",
              "createdOn": "2024-08-26T13:36:54.942Z",
              "lo2": "Identify the importance of preserving and utilizing rainwater as a valuable natural resource in the context of water resources and conservation",
              "lo1": "Recognise the significance of rainwater in different parts of Rajasthan as the purest form of natural water",
              "lo4": "NA",
              "lo3": "NA",
              "strand": "Geography",
              "bloomsTaxonomy": "UNDERSTAND",
              "skill": "Core Skills",
              "correctAnswer": "A",
              "questionStatement": "_____, जो राजस्थानातील विविध भागांमध्ये पावसाच्या पाण्याच्या नावाने ओळखला जातो, नैसर्गिक पाण्याचा सर्वात शुद्ध प्रकार मानला जातो.",
              "subStrand": "Water resources",
              "level": "Secondary",
              "updatedOn": "NA",
              "complexityLevel": "P1",
              "grade": "Grade 10",
              "hint": "NA",
              "topic": "Methods of Rainwater harvesting",
              "proficiencyLevel": "NA",
              "questionOptions": [
                  {
                      "optionKey": "A",
                      "optionValue": "Palar Pani"
                  },
                  {
                      "optionKey": "B",
                      "optionValue": "Johad"
                  },
                  {
                      "optionKey": "C",
                      "optionValue": "Khadin"
                  },
                  {
                      "optionKey": "D",
                      "optionValue": "Kul"
                  }
              ],
              "answerExplanation": "The correct answer is option A: Palar Pani. In different parts of Rajasthan, Palar Pani is known as rainwater and is considered the purest form of natural water because it is free from pollutants and chemicals.",
              "questionType": "MULTIPLE_CHOICE",
              "language": "marathi"
          }
      ];
      let uniquesSubjects = this.getUniqueSubjects(); 
      let uniqueSkills = this.getUniqueSkills();
      let assessmentDraftData = {
        id: assessment.id,
        assessmentName: assessment.assessmentName || "Untitled",
        modeOfAssessment: assessment.modeOfAssessment || "",
        assessmentDescription: assessment.assessmentDescription || "",
        status: status,
        availableLanguages: assessment.availableLanguages || "",
        isReshuffleRequired: assessment.isReshuffleRequired || true,
        level: assessment.level || "",
        grade: assessment.grade || "",
        noOfQuestion: assessment.noOfQuestion || 0,
        questions: this.questionsToUpload,
        subjects: uniquesSubjects, 
        marksAllotted: this.totalMarks,
        skills: uniqueSkills, //depends on uploaded questions csv
        isSoftDeleted: false,
        deletedQuestions: this.assessmentData.isEdit?this.assessmentData.deletedQuestions:[],
      }
      let response = await StaticAssessmentController.createStaticAssessment(assessmentDraftData);
      if(response.data.flag){
        this.assessmentPublishedDialog = status == 'PUBLISHED'
        this.assessmentDraftDialog= status == 'DRAFT'
        this.successDialogLoader = false;
      }
      // truncating relevant states of vuex store on completion of assessment creation flow
      this.$store.dispatch('setQuestionForEdit',{});
      this.$store.dispatch('setAssessmentData',{});
    },
    cancelStaticAssessmentCreation(){
      // truncating relevant states of vuex store on completion of assessment creation flow
      this.$store.dispatch('setQuestionForEdit',{});
      this.$store.dispatch('setAssessmentData',{});

      this.$router.replace('/static-assessment');
    },
    closeApprovalDialog(){
      this.approvalDialog = false;
      for(const key in this.approverData){
        this.approverData[key]='';
      }
    },
    sendForApproval(){
      console.log(this.approverData)
      alert("{send for approval} function call here")
      //function to be deleted on dev complete
      //logic for sendForApprovalLoader to be added
    },
    async getRoles() {
      const response = await RolesController.getRoles();
      if (response.data.flag) {
        this.roles = response.data.data.filter(role=> role.assessment.admin==false && role.assessment.read == true);
        this.openApprovalDialogLoader = false;
        if(this.e1==2)this.approvalDialog=true;
      } else {
        alert("Some thing went wrong while fetchin all roles");
      }
    },
    getUsersForRole(roleId){
      this.approverData.userId = '';
      this.usersForRole = this.roles.find(role=>role.id==roleId).users;
    },
    // async getGrades() {
    //   const response = await GradeController.getAllGrades();
    //     console.log("resss",response)
    //   if (response.status == 200) {
    //     if (response.data.grades.length > 0) {
    //       // this.gr = response.data.grades;
    //       this.grades = await response.data.grades.map((e) => e.name);
    //     } else {
    //       this.grades = [];
    //     }
    //   } else {
    //     alert(response.data.error);
    //   }
    //   // console.log("level data", this.levelData.rows);
    // },
    displayQuestionList(){
      this.displayQuestion=true;
      this.showConfirmDialog=false;
      this.isDownloadtemplete=false;
    },
    onCancel(){
      this.questionIds = [];
      this.failedRows = [];
      this.successfullRowCount = 0;
      this.failedRowCount = 0;
      this.uploadingTopicsDialog=false;
      this.singleQuestionFailed = false;
    },
    uploadConfirm(){
      this.questionsToUpload.push(...this.bulkQuestionJsonData);
      this.allQuestions.push(...this.getQuestionsForLanguage("English", this.bulkQuestionJsonData));

      this.uploadingTopicsDialog=false;
      this.showConfirmDialog=true;

      this.getSubjectCount();
      this.calculateTotalMarks();
      this.failedRows = [];
      this.failedRowCount = 0;

    },
    getSubjectCount(){
      this.englishCount = this.countBySubject('English');
      this.mathCount = this.countBySubject('Mathematics');
      this.logicalReasoningCount = this.countBySubject('Logical Reasoning');
      this.scienceCount = this.countBySubject('Science');
    },
    calculateTotalMarks() {
      this.totalMarks = this.allQuestions.reduce((sum, question) => {
        console.log('total ques', question.languageOfQuestion);
        const marks = question.marksAllotted === "NA" ? 1 : Number(question.marksAllotted);
        return sum + marks;
        return sum; // Always return the current sum
      }, 0);
      
      console.log('Total Marks:', this.totalMarks);
    },
    countBySubject(subject) {
      const uniqueQuestions = new Set(); // To store unique question IDs
     
      this.allQuestions.forEach(item => {
       
          if (item.subject.includes(subject)) {
              uniqueQuestions.add(item.questionId); // Add questionId to the set
          }
      });
      
      return uniqueQuestions.size; // Return the count of unique questions
    },
    async downloadTemplate(){
      try {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/csv_templates%2FTopics%20Bulk%20Upload%20-%20Sheet1.csv?alt=media&token=59bf0dcc-d16b-4f7b-81aa-e30fb37bf125';
        const response = await axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob' // Ensure response is treated as a Blob
        });

        // Create a temporary URL for the Blob
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'downloaded_file.csv'); // Set desired filename

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link to initiate download
        link.click();

        // Clean up: remove the link and revoke the Blob URL
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    selectFile() {
      // Trigger the file input click
      this.$refs.fileInput.click();
      
      this.isAddMoreEnabled = true;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.csvFileToJSON(file);
        // Reset the input value
        event.target.value = ''; // Allow the same file to be selected again
      }
    },

    csvFileToJSON(file) {
      console.log('file',file.name)
      var contents = [];
      this.fileName=file.name
      
      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;
            this.parsed = true;
            contents = this.content.data;
            var totalRowCount = contents.length - 1;
            console.log('ndmvmnvxb',totalRowCount)
            var jsonData = [];
            var removedQuestion = [];
            var headers = contents[0];
            this.successfullRowCount = 0;
            this.failedRowCount = 0;
            console.log('this.failedRowCount',this.failedRowCount)
            console.log('this.successfullRowCount',this.successfullRowCount)
            console.log('totalRowCount',totalRowCount)
            console.log('noOfQuestion',this.assessmentData.noOfQuestion)
            console.log('this.availableLanguages.length',this.assessmentData.availableLanguages.length)
            console.log('this.isAddMoreEnabled',this.isAddMoreEnabled)
            console.log('this.allQuestions.length',this.allQuestions.length)
            if(!this.isAddMoreEnabled && totalRowCount != (this.assessmentData.noOfQuestion * this.assessmentData.availableLanguages.length))
            {
              
              this.openCountErrorDialog();
              
            }
            else if(this.isAddMoreEnabled && ((totalRowCount + (this.allQuestions.length * this.assessmentData.availableLanguages.length)) != (this.assessmentData.noOfQuestion * this.assessmentData.availableLanguages.length)))
            {
             
              this.openCountErrorDialog();
              
            }
            else{
             
              const requiredLanguage = this.assessmentData.availableLanguages;

              const grade = this.assessmentData.grade;
              console.log('selectedGrade',grade)
              // Track occurrences of questionID for each language
              const questionLanguageMap = {};

              const mandatoryFields = [
                      'product', 'questionId', 'level', 'grade', 'skill', 
                      'HRIS Unique Role', 'subject', 'topic', 
                      'question type', 'languageOfQuestion', 
                      'questionStatement', 'options', 
                      'correctAnswer', 'answerExplanation', 
                      'bloomsTaxonomy', 'difficultyLevel', 'authoredBy'
              ];
              // Collecting subjects and question IDs
              const questions = {};
              for (var i = 1; i < contents.length; i++) {       
                var rowData = {};
                var options = [];
                var cells = contents[i];
                var failureReason = "";

                let questionId = '';
                let languageOfQuestion = '';

                // Dynamically find the indices for Question ID and Language
                headers.forEach((header, index) => {
                  if (header.trim() === 'questionId') {
                    
                      questionId = cells[index] ? cells[index].trim() : '';
                  } else if (header.trim()=== 'languageOfQuestion') {
                  
                    languageOfQuestion = cells[index] ? cells[index].trim() : '';
                  }            
                });   
                
                if (!questions[questionId]) {
                  questions[questionId] = new Set();
                }
                // Add the language to the set for this question ID
                if (languageOfQuestion) {
                  questions[questionId].add(languageOfQuestion);
                }
                console.log('languageOfQuestion',questions[questionId])
                // Create a combined key for questionID and language
                const questionLangKey = `${questionId}|${languageOfQuestion}`;

                // Initialize map entry for questionLangKey if it doesn't exist
                if (!questionLanguageMap[questionLangKey]) {
                    questionLanguageMap[questionLangKey] = 0;
                }

                // Check for mandatory fields
                const missingFields = mandatoryFields.filter(field => {
                    const index = headers.indexOf(field);
                    return index !== -1 && (!cells[index] || cells[index].trim() === '');
                });
                console.log('missingFields',missingFields)
                if (missingFields.length > 0) {
                    failureReason = `Missing mandatory fields: ${missingFields.join(', ')}`;
                    removedQuestion.push(i);
                    // this.skippedQuestion(i, failureReason);
                    // continue; // Skip this row
                }

                let questionType = null
                for (var j = 0; j < cells.length; j++) {
                  if (mandatoryFields.includes(headers[j])) {
                      rowData[headers[j]] = cells[j] ? cells[j].trim() : ''; // Set empty string if value is missing
                  }
                  
                  if(headers[j].includes("languageOfQuestion")){
                   
                    if(!requiredLanguage.includes(cells[j])){

                      if (!removedQuestion.includes(i)) {
                      
                        removedQuestion.push(i);
                        failureReason = "Language is not a part of assessment";
                      
                        this.skippedQuestion(i, failureReason);
                      }
                    continue;

                    }
                  }
                  
                  if (cells[j].trim().length === 0) {
                    
                    if (!removedQuestion.includes(i)) {
                      
                      removedQuestion.push(i);
                      failureReason = `Blank cell in column: ${headers[j]}`; 
                      this.skippedQuestion(i, failureReason);
                    }

                    continue;
                  }
                  if (headers[j].includes("grade")) {
                    console.log('grade',cells[j])
                              // Check if the grade matches the selectedGrade

                      if (cells[j].trim() !== grade) {
                        
                          removedQuestion.push(i); // Add to removed questions if grade does not match
                          failureReason = `Grade ${cells[j]} does not match selected grade ${grade}`;
                          this.skippedQuestion(i, failureReason);
                      }
                        rowData[headers[j]] = cells[j].indexOf('Grade ') === -1 && cells[j] !== 'NA' ? `Grade ${cells[j]}` : `${cells[j]}`;
                        continue;
                  }
                  
                
                  var skillIndex = headers.indexOf("skill");
                
                  if (headers[j].includes("skill")) {
                    if (!this.uniqueSkills.includes(cells[j])) {
                      this.uniqueSkills.push(cells[j]);
                    }
                  }
                  if (headers[j].includes("difficultyLevel")) {
                    rowData[headers[j]] = cells[j].toUpperCase();
                    continue;
                  }
                  if (headers[j].includes("complexityLevel")) {
                    rowData[headers[j]] = cells[j].toUpperCase();
                    continue;
                  }
                  
                  var key = headers[j];
                          key = "" + key;
                          var value = cells[j].trim();

                          if (key.startsWith("optionKey")) {
                              var option = { optionKey: value };
                              options.push(option);
                          } else if (key.startsWith("optionValue") && value.includes("https://drive.google.com/file/")) {
                              var found = value.match(/\/d\/([A-Za-z0-9-]+)/);
                              if (found && found[1].length) {
                                  value = "https://drive.google.com/uc?export=view&id=" + found[1];
                              }
                              var lastOptionIndex = options.length - 1;
                              if (lastOptionIndex >= 0) {
                                  options[lastOptionIndex].optionValue = value;
                              }
                          } else if (key.startsWith("optionValue")) {
                              var lastOptionIndex = options.length - 1;
                              if (lastOptionIndex >= 0) {
                                  options[lastOptionIndex].optionValue = value;
                              }
                          } else {
                              rowData[key] = cells[j].trim();
                              if (key == 'subject') {
                                  rowData[key] = cells[j].split(',').map(item => item.trim());
                              }
                          }

                          if (headers[j].includes("questionType")) {
                              questionType = cells[j];
                          }
                          if (questionType === 'TRUE_FALSE') {
                              options = [
                                  { optionKey: "A", optionValue: "TRUE" },
                                  { optionKey: "B", optionValue: "FALSE" },
                              ];
                          }
                        rowData["questionOptions"] = options;
                        

                }
                
                // Increment count for this combination
                questionLanguageMap[questionLangKey]++;

                // Check if the count exceeds the allowed limit (1)
                if (questionLanguageMap[questionLangKey] > 1) {
                    failureReason = `Duplicate question for questionId: ${questionId}, language: ${languageOfQuestion}`;
                    // this.failedRows.push({ ...rowData, Reason: failureReason });
                    removedQuestion.push(i);


                }

                if (i != 0) {
                  if (!removedQuestion.includes(i)) {
                    jsonData.push(rowData);
                    this.successfullRowCount++;
                  } else {
                    // If removed, add the reason to failedRows
                      this.failedRows.push({ ...rowData, Reason: failureReason });
                      this.failedRowCount++;
                  }
                }
                console.log('content length',totalRowCount)
                console.log('question id size',questions[questionId].size)
                console.log('in single question before',this.singleQuestionFailed)
                if (totalRowCount === 3 && questions[questionId].size > 1 && this.failedRowCount > 0) { 
                    // Only one question
                      
                      this.singleQuestionFailed = true;
                      console.log('in single question',this.singleQuestionFailed)
                  }
              }
              
              const mergedData = [...jsonData, ...this.failedRows];
              
              this.validateLanguages(mergedData, requiredLanguage);

              console.log('this.failedRowCount',this.failedRowCount)
              console.log('this.successfullRowCount',this.successfullRowCount)
              console.log('jsonData',jsonData)
              this.bulkQuestionJsonData = jsonData.filter(question => !this.questionIds.includes(question.questionId));
            
              this.uploadTopics();
            }
           
           
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },
    validateLanguages(jsonData, requiredLanguage)
    {
      const groupedQuestions = {};
    
      // Step 2: Group questions by questionID and collect unique questionIDs
      jsonData.forEach(question => {
        if (!groupedQuestions[question.questionId]) {
          groupedQuestions[question.questionId] = [];
          // this.questionIds.push(question.questionId); // Add questionID to the array
        }
        groupedQuestions[question.questionId].push(question);
      });

      
      // Step 2: Check for missing languages and collect the required question objects
      
      for (const questionId in groupedQuestions) {
        const languagesAvailable = groupedQuestions[questionId].map(q => q.languageOfQuestion);
        
        // Find missing languages
        const missingLanguages = requiredLanguage.filter(lang => !languagesAvailable.includes(lang));

        if (missingLanguages.length > 0) {
          groupedQuestions[questionId].forEach(question => {
            // Append the reason for missing languages
           
            if (!this.questionIds.includes(question.questionId)) {
                this.questionIds.push(question.questionId);
            }
            this.failedRows.push({
              ...question,
              reason: `Missing languages: ${missingLanguages.join(', ')}`
            });
            this.successfullRowCount--;
            this.failedRowCount++; // Increment failedRowCount

          });
        }
      }
     
     
    },
    downloadFailedRows() {
    
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    let wsData = [];

    // Prepare the data for the worksheet
    if (this.failedRows?.length === 0) return; 

    if(this.failedRows?.length === 1 && Object.keys(this.failedRows[0]).length === 1 && this.failedRows[0]?.hasOwnProperty('Reason')){
      // Create header and value rows
      const headers = ['Reason'];
        const row = [this.failedRows[0].Reason]; // Assuming Reason is a direct property

        wsData.push(headers); // Add headers to wsData
        wsData.push(row); // Add the reason value to wsData
     
    }
    else{
      // Get the base headers from the first object
      // const baseHeaders = Object.keys(this.failedRows[0]);

      const baseHeaders = Object.keys(this.failedRows[0]).filter(key => key !== 'questionOptions');
      
      
      // Determine the maximum number of options
      let maxOptionsCount = 0;
      this.failedRows.forEach(row => {
          const options = row.questionOptions || [];
          if (options.length > maxOptionsCount) {
              maxOptionsCount = options.length;
          }
      });

      // Create dynamic headers for options
      const optionHeaders = [];
      for (let i = 1; i <= maxOptionsCount; i++) {
          optionHeaders.push(`optionKey${i}`, `optionValue${i}`);
      }

      // Combine base headers with dynamic option headers
      const headers = [...baseHeaders, ...optionHeaders];
     
      wsData = [headers]; // Initialize wsData with headers

      this.failedRows.forEach(row => {
          // Handle question options
          const options = row.questionOptions || []; // Make sure to handle undefined

          // Create a new row object, excluding questionOptions
          const newRow = { ...row };
          delete newRow.questionOptions; // Remove questionOptions from the row
          // Create an array to hold the final row data
          const finalRow = headers.map(header => newRow[header] || ''); // Fill existing data or leave empty

          // Populate option values
          options.forEach((option, index) => {
              if (index < maxOptionsCount) {
                  finalRow[baseHeaders.length + index * 2] = option.optionKey;   // Set optionKey
                  finalRow[baseHeaders.length + index * 2 + 1] = option.optionValue; // Set optionValue
              }
          });

          // console.log('finalRow',)
          wsData.push(finalRow); // Add the final row to wsData
      });
    
    }
    // Convert data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // // Apply styles to each cell based on the subject
    // for (let i = 0; i < this.failedRows.length; i++) {
    //     const subject = this.failedRows[i].subject; // Array of subjects
    //     const rowIndex = i + 1; // Adjust for header row

    //     for (let j = 0; j < wsData[i + 1].length; j++) { // Start from row 1 (the first data row)
    //         const cellAddress = XLSX.utils.encode_cell({ c: j, r: rowIndex });

    //         if (!ws[cellAddress]) ws[cellAddress] = {}; // Ensure the cell exists

    //         // Check the subjects array for specific subjects
    //         if (subject.includes('English')) {
    //             ws[cellAddress].s = { fill: { fgColor: { rgb: "CCCCCC" } } }; // Grey
    //         } else if (subject.includes('Mathematics')) {
    //             ws[cellAddress].s = { fill: { fgColor: { rgb: "0000FF" } } }; // Blue
    //         }
    //     }
    // }
  

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Failed Uploads');

    // Generate a binary string for the workbook
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link and set the URL using the blob
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'failed_uploads.xlsx');
    link.style.visibility = 'hidden';

    // Append the link to the body, click it to trigger the download, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
},
    decodeHtmlEntities(text) {
      const entities = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&nbsp;': ' ',
      };
      return text.replace(/&(amp|lt|gt|quot|#39|nbsp|#(\d+));/g, function(match, entity, dec) {
        if (entity === 'quot') return '"';
        if (entity === '#39') return "'";
        return entities[entity] || String.fromCharCode(dec);
      });
    },
    skippedQuestion(id, reason) {
      console.log("reason");
      if (reason == "") {
      } else {
        this.removedQuestion.push(
          "Q" + id + ". Failed" + "->reason-> " + reason
        );
      }
    },
    downloadTextFile(text, filename) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    multiSubjectCheck(subjects){
      return subjects.split(',').every((sub) => this.checkData.subjects.includes(sub.trim()))
    },
    checkMatters(k, word, skill) {
      switch (k) {
        case 11:
          return this.questionTypeList.includes(word.trim());
        case 0:
          if (skill == "Core Skills") {
            return this.checkData.levels.includes(word.trim());
          } else {
            return true;
          }
        case 1:
          if (skill == "Core Skills") {
            return this.checkData.grades.includes(`Grade ${word.trim()}`);
          }
          return true
        case 25:
          return this.difficultyLevels.includes(word.trim());
        case 26:
          if (skill == "Core Skills") {
            return this.complexityLevels.includes(word.trim());
          } else {
            return true;
          }
        case 2:
          return this.checkData.skills.includes(word.trim());
        case 3:
          if (word == "NA" || word == "na") {
            return true;
          }
          return /,/.test(word.trim()) ? this.multiSubjectCheck(word.trim()) : this.checkData.subjects.includes(word.trim());
        default:
          return true;
      }
    },   
    uploadTopics() {
      
      this.uploadingTopicsDialog = true;
      // console.log('uploadingTopicsDialog',this.uploadingTopicsDialog)
      this.uploadTopicsUsers = false;
      console.log('bulk upload',this.bulkQuestionJsonData)
      var index = 1;
      this.bulkQuestionJsonData.forEach(async (topic_) => {
        try {
          const response = await TopicsController.createTopic(topic_);
          if (response.status == 200) {
            console.log(topic_);
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Successfully!",
            });
          } else {
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Failed!",
            });
          }
          index++;
        } catch (error) {
          console.log(error);
        }
        // console.log(this.bulkTopicUploadResponse);
      });
    },


    async goto(step) {
      step =  Number(step);
      switch (step) {
        case 1:
        // this.assessmentFormNotFilled();
          if (this.$refs.step1.validate()) {
            this.dLoading = true;
          }
          this.e1+=1;
          if(this.allQuestions.length > 0) {
            this.calculateTotalMarks();
          }
          break;
        case 2:
          this.createStaticAssessment(this.assessmentData, 'PUBLISHED')
          break;
        default:
        
      }
    },
    goBack() {
      this.e1-=1;
    },
    selectAll(type) {
      if (type === "languages") {
        if (this.assessmentData.availableLanguages?.length === this.languageOptions?.length ) {
          // If all languages are selected, deselect all
          this.assessmentData.availableLanguages = [...this.defaultLanguages];
        } else {
          // Select all languages
          this.assessmentData.availableLanguages = [...this.languageOptions]
        }
      }
    },
    

    deleteChip(type, item) {
      switch(type){
        case "languages":
          if(!this.defaultLanguages.includes(item)){
            let index = this.assessmentData.availableLanguages.indexOf(item);
            this.assessmentData.availableLanguages.splice(index, 1);
          }
      }
    },
    handleClear(){
      this.assessmentData.availableLanguages = [...this.defaultLanguages];
      this.$nextTick(() => {
        this.assessmentData.availableLanguages = [...this.defaultLanguages];
      });
    },


    onEditorReady(editor) {
      editor.on("afterCommandExec", function (event) {
        var commandName = event.data.name;
        if (
          [
            "ckeditor_wiris_openFormulaEditor",
            "ckeditor_wiris_openFormulaEditorChemistry",
          ].includes(commandName)
        ) {
          setTimeout(() => {
            const wiris_license_overlays =
              document.querySelectorAll(".wrs_tickContainer");
            wiris_license_overlays.forEach((element) => {
              element.remove();
            });
          }, 2000);
        }
      });
    },
    transformQuestion(questions){
      const transformedQuestions = {};
      questions.forEach((question) => {
      if(question.languageOfQuestion){
        const {  questionId, languageOfQuestion, questionStatement, questionAssetUrl, questionOptions, strand, topic, lo1, lo2, lo3, lo4, subStrand, answerExplanation, hint, ...rest } = question;
        if (!transformedQuestions[questionId]) {
          transformedQuestions[questionId] = { ...rest, QuestionDetails: {} };
        }
        transformedQuestions[questionId].QuestionDetails[languageOfQuestion.toLowerCase()] = {
            questionStatement,
            questionAssetUrl,
            questionOptions,
            strand,
            topic,
            lo1,
            lo2,
            lo3,
            lo4,
            subStrand,
            answerExplanation,
            hint
        };
      }
      //bypass for already transformed questions
        else{ 
          const {questionId, ...rest} = question;
          transformedQuestions[questionId] = {...rest};
        }
      });

      return Object.entries(transformedQuestions).map(([questionId, questionData]) => ({
        questionId,
        ...questionData,
      }));
    },
    redirectToEditStaticAssessmentQuestion(questionId) {
      let transformedQuestionTobeEdited;
      //code block for fresh creation run
      if(!this.assessmentData.isEdit){
        // stash questions payload along with assessment data in vuex store
        this.assessmentData.questions = [...this.questionsToUpload];
        this.$store.dispatch('setAssessmentData', this.assessmentData);
        transformedQuestionTobeEdited = this.transformQuestion(this.questionsToUpload.filter(question=>question.questionId==questionId)).pop();
      }

      // code block for edit and drafting runs
      else{
        if(this.questionsToUpload.length > 0)this.assessmentData.questions.push(...this.questionsToUpload);
        this.$store.dispatch('setAssessmentData', this.assessmentData);

        transformedQuestionTobeEdited = this.transformQuestion(this.assessmentData.questions.filter(question=>question.questionId==questionId)).pop();
      }

      this.$store.dispatch('setQuestionForEdit', transformedQuestionTobeEdited);
      this.$router.push({ path: "/edit-static-assessment", query: { questionId }});
    },

    async getAssessmentForId(id){
      this.assessmentData.id = id;
      let response = await StaticAssessmentController.getStaticAssessmentsById(id);
      if(response.data.flag){
        let data = response.data.assessments[0];
        for (const key in this.assessmentData) {
          if (data[key] !== undefined) {
            this.assessmentData[key] = data[key];
          }
        }
        if(this.assessmentData.questions.length>0){
          this.allQuestions = this.assessmentData.questions.map(({QuestionDetails, ...rest})=>{
              return{
                questionStatement:QuestionDetails.english.questionStatement,
                 ...rest
                }
            });
        }
        this.assessmentData.deletedQuestions = this.assessmentData.questions.map(({questionId})=>questionId);
        this.assessmentData.isEdit = true;
        this.englishCount = this.countBySubject('English');
        this.mathCount = this.countBySubject('Mathematics');
        this.logicalReasoningCount = this.countBySubject('Logical Reasoning');
        this.scienceCount = this.countBySubject('Science');

        if(this.assessmentData.level !== '')this.getGradesMappedToLevels("", true);
        // if(!this.assessmentFormNotFilled)this.e1=2;
      }
    },
   getQuestionsForLanguage(language, questions){
      //'questions' can be of two types, transformed(as stored in db) or untransformed(as on csv upload)
      //This function can filter either type or a composite array of questions consisting of both types simultaneously
      let questionsOfLanguage = [];
      questions.forEach(({QuestionDetails, languageOfQuestion, ...rest})=>{
        if(QuestionDetails){
          questionsOfLanguage.push({
            questionStatement: QuestionDetails[language?.toLowerCase()]?.questionStatement,
              ...rest
          })
        }
        else if(languageOfQuestion === language){
          questionsOfLanguage.push({languageOfQuestion, ...rest})
        }
      })
      return questionsOfLanguage; 
    },
  },
  computed: {
    ...mapGetters({
      staticAssessmentData : 'getAssessmentData',
      questionForEdit: 'getQuestionForEdit'
    }),
    assessmentFormNotFilled() {
       return    (
        this.assessmentData.assessmentName == '' 
        || this.assessmentData.level == ''
        || this.assessmentData.level == undefined 
        || this.assessmentData.grade == ''
        || this.assessmentData.grade == undefined
        || this.assessmentData.modeofAssessment == ''
        || this.assessmentData.availableLanguages.length == 0
        || this.assessmentData.noOfQuestion == ''
        )
    },
    isLastStep(){
      return this.e1 == 2;
    },


  },
  async created() {
    // await this.getRoles();
    if (!this.$store.state.role.assessment.read) {
      this.$router.push("/notFound");
    }
    // this.getGrades();
    await this.getLevels();
    this.assessmentData.availableLanguages = [...this.defaultLanguages];
    this.$store.state.breadcrumb = "Assessment";

    //populate form fields when editing assessment
    if(this.$route.query.id !== undefined){
      this.getAssessmentForId(this.$route.query.id);
      if(this.$route.query.e1!==undefined)this.e1 = this.$route.query.e1;
    }

    //populate form fields on redirection back from question edit
    else if(this.$route.query.e1 !== undefined){
      // unpack assessment data stashed in vuex store
      this.assessmentData = {...this.staticAssessmentData};
      this.questionsToUpload = this.assessmentData.questions;
      // populating questions array for display
      this.allQuestions.push(...this.getQuestionsForLanguage("English", this.assessmentData.questions));

      if(this.assessmentData.level !== '')this.getGradesMappedToLevels("", true);

      //push edited question to questions arrays(display array and API payload array)
      if(Object.keys(this.questionForEdit).length > 0){
        const {QuestionDetails, ...rest} = this.questionForEdit;
        this.allQuestions.push({
          questionStatement: QuestionDetails.english.questionStatement,
          ...rest});
        this.questionsToUpload.push(this.questionForEdit);
      }
      this.e1 = this.$route.query.e1;
    }
  },
};
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px;
}
.fixBarCreateCammpaign{
  width:93%;
}

.box2 {
  width: 70rem;
}
.total-questions-and-marks-card{
  border: 1px solid #864F20 !important;
  color: #864F20 !important;
}
.create-assessment-card-outline{
  border: 1px solid #0000001F !important;
}
.total-questions-and-marks-divider{
  border-color: #864F20 !important;
}
.create-assessment-divider{
  border-width: 1px;
}
.label-value{
  color: #000000DE !important;
}
.pad-create-assessment{
  padding-top: 120px!important;
}
.radio-group-align{
  margin-top:-8px!important
}
.stepper-shadow{
  height: 50px;
}
.cke_notification .cke_notification_warning{
  display: none !important;
}
</style>
